/**
 * 获取dom子元素
 *
 * @param {*} element dom元素
 * @param {*} tagName tag node类型
 * @returns {array}
 */
function children(element, tagName) {
    const nodeList = element.childNodes;
    let ary = [];
    if (/MSIE(6|7|8)/.test(navigator.userAgent)) {
        for (let i = 0; i < nodeList.length; i++) {
            let curNode = nodeList[i];
            if (curNode.nodeType === 1) {
                ary[ary.length] = curNode;
            }
        }
    } else {
        ary = Array.prototype.slice.call(element.children);
    }

    // 获取指定子元素
    if (typeof tagName === 'string') {
        for (let k = 0; k < ary.length; k++) {
            const curTag = ary[k];
            if (curTag.nodeName.toLowerCase() !== tagName.toLowerCase()) {
                ary.splice(k, 1);
                k--;
            }
        }
    }
    return ary;
};

/**
 * 添加样式
 *
 * @param {*} element dom元素
 * @param {*} cName 样式名称
 * @returns 无返回
 */
function addClass(element, cName) {
    if (!hasClass(element, cName)) {
        element.className += ' ' + cName;
    }
};

/**
 * 移除样式
 *
 * @param {*} element dom元素
 * @param {*} cName 样式名称
 * @returns 无返回
 */
function removeClass(element, cName) {
    if (hasClass(element, cName)) {
        element.className = element.className.replace(new RegExp('(\\s|^)' + cName + '(\\s|$)'), ' ');
    };
};

/**
 * 是否有指定样式
 *
 * @param {*} element dom元素
 * @param {*} cName 样式名称
 * @returns {boolean}
 */
function hasClass(element, cName) {
    return !!element.className.match(new RegExp('(\\s|^)' + cName + '(\\s|$)'));
};

export default{
    addClass,
    removeClass,
    hasClass,
    children
};
