<template>
    <el-dialog class="chapter-view" :visible.sync="dialogVisible" v-loading="isLoading" element-loading-text="拼命加载中" :fullscreen="true" @close="goback">
        <div slot="title" class="chapter-view-header">
            <el-button type="text" class="chapter-view-goback" @click="goback">
                <i class="el-icon-arrow-left"></i>
                <span class="chapter-view-goback-text">{{comicName}}</span>
            </el-button>
            <div class="chapter-view-header-center">
                <span class="chapter-view-header-switch__page">第{{page}}页</span>
                <el-select v-model="currentChapterId" placeholder="请选择" @change="chapterChange">
                    <el-option v-for="item in cloneChapterList" :key="item.chapterId" :value="item.chapterId" :label="item.chapterSN + ' ' + item.chapterName">
                        <span class="chapter-view-chapter-sn">{{item.chapterSN}}</span>
                        <span class="chapter-view-chapter-name">{{item.chapterName}}</span>
                    </el-option>
                </el-select>
                <div class="chapter-view-header-switch">
                    <el-button type="primary" size="mini" @click="switchMode">{{ switchText }}</el-button>
                    <span class="chapter-view-header-switch__desc">点击可切换浏览模式</span>
                </div>
            </div>
            <div class="chapter-page-option">
                <span class="chapter-page-current">{{currentChapterSub + 1}}</span> / <span class="chapter-page-total">{{chapterTotal}}</span>
            </div>
        </div>
        <div class="chapter-view-body">
            <vertical-view
                v-if="!isHorizontal"
                :pages="cloneChapterList"
                :moveToChapterId.sync="chapterId"
                :loading.sync="isLoading"
                @onMove="onChapterMove"
                @onRunImage="runImageObserver"
                @onClose="goback"
            />
            <horizontal-view
                v-if="isHorizontal"
                :pages="cloneChapterList"
                :moveToChapterId.sync="chapterId"
                :loading.sync="isLoading"
                @onMove="onChapterMove"
                @onClose="goback"
            />
        </div>
        <ul class="chapter-view-page-menu" ref="chapterMenuBox" v-scrollbar>
            <li class="single-ellipsis" v-for="item in cloneChapterList" :title="item.chapterName" :id="'chapterMenu' + item.chapterId" :class="{'chapter-view-page-item': true, active: currentChapterId === item.chapterId, 'unshelve': item.showStatus === 0}" :key="item.chapterId" @click="chapterChange(item.chapterId)">{{item.chapterSN}} {{item.chapterName}}</li>
        </ul>
    </el-dialog>
</template>
<script>
/**
 * 章节预览：
 *    1、可以跨章、跨页预览
 *    2、如果有当前章节数据，优先展示当前章节预览，否则展示当前漫画第一章节
 * @props {comicName} 漫画名称
 * @props {comicId} 漫画id
 * @props {chapterId}  当前章节id，如果有值，者使用确定为预览单章节
 */
import VerticalView from './components/VerticalView';
import HorizontalView from './components/HorizontalView';
export default {
    components: { VerticalView, HorizontalView },
    props: {
        comicName: {
            type: [String, Number],
            default: ''
        },
        comicId: {
            type: [String, Number],
            default: ''
        },
        chapterId: [String, Number]
    },
    data() {
        return {
            isHorizontal: false, // 是否横屏查看
            isLoading: false,
            message: null,
            dialogVisible: false,
            cloneChapterList: [],
            currentChapterId: null, // 当前章节id
            entryQueue: [],
            page: 1
        };
    },
    computed: {
        // 当前漫画总话数
        chapterTotal() {
            return this.cloneChapterList.length;
        },
        // 章节key：id map
        chapterMap() {
            let _map = {};
            this.cloneChapterList.forEach(el => (_map[el.chapterId] = el));
            return _map;
        },
        // 当前章节
        currentChapter() {
            return this.chapterMap[this.currentChapterId] || {};
        },
        // 当前章节下标
        currentChapterSub() {
            return this.cloneChapterList.indexOf(this.currentChapter);
        },
        switchText() {
            return this.isHorizontal ? '左右模式' : '上下模式';
        }
    },
    watch: {
        chapterId: {
            immediate: true,
            handler(newId, oldId) {
                if (!newId && !oldId) {
                    return;
                }
                if (newId !== oldId) {
                    this.currentChapterId = newId;
                    this.$nextTick(() => {
                        this.scrollChapterMenuBox(this.currentChapterId);
                    });
                }
            }
        }
    },
    created() {
        this.getChapterList();
        document.onkeyup = this.keyEventCallback;
        this.initObserver();
    },
    beforeDestroy() {
        document.onkeyup = null;
    },
    methods: {
        switchMode() {
            // 同步当前阅读章节id，并且切换模式
            this.$emit('update:chapterId', this.currentChapterId);
            this.isHorizontal = !this.isHorizontal;
        },
        // 开启元素视窗监听
        runImageObserver(doms) {
            doms.forEach((dom, index) => {
                this.intersectionObserver.observe(dom);
            });
        },
        initObserver() {
            this.intersectionObserver = new IntersectionObserver((entries) => {
                entries.forEach((target, index) => {
                    const dom = target.target;
                    const { chapterIndex } = this.getTargetAttr(dom);
                    if (target.isIntersecting) { // 如果在队列，又出队了，则不进行加载
                        this.page = chapterIndex;
                        return false;
                    }
                });
            });
        },
        // 获取第几张图片，章节id
        getTargetAttr(dom) {
            const chapterId = dom.getAttribute('chapter-pid');
            const chapterIndex = dom.getAttribute('chapter-index') - 0;
            return { chapterId, chapterIndex };
        },
        // 展示预览页面
        openDialog() {
            this.dialogVisible = true;
        },
        // 关闭预览页面
        goback() {
            this.dialogVisible = false;
        },
        // 格式化章节图片地址
        formatImageUrl(url) {
            const _url = url ? (this.$config.cdn + url) : '';
            return this.$utils.filterImagePath(_url);
        },
        // 在没有章节列表的情况下，获取所有章节数据
        getChapterList() {
            if (!this.$utils.isEmptyObject(this.currentChapter) || !this.cloneChapterList.length) this.getComicChapterList();
        },
        // 获取章节列表
        getComicChapterList() {
            this.$api('getComicChapterList', { comicId: this.comicId, ordertype: 1, pageIndex: 1, pageSize: 10000 }).then(res => {
                this.cloneChapterList = res.data.rows;
            }).catch(() => (this.cloneChapterList = [this.currentChapter]));
        },
        // 切换章节
        chapterChange(chapterId) {
            this.$emit('update:chapterId', chapterId);
        },
        /**
         * 滚动章节导航栏
         *  chapter {Number|Object}
        */
        scrollChapterMenuBox(chapter) {
            let id = chapter;
            if (typeof chapter === 'object') id = chapter.chapterId;
            const chapterMenuBox = this.$refs.chapterMenuBox;
            const { scrollTop: chapterMenuBoxTop, clientHeight: chapterMenuBoxHeight } = chapterMenuBox;
            const _chapterSub = this.cloneChapterList.findIndex(item => item.chapterId === id);
            const offsetY = _chapterSub * 30;
            if (chapterMenuBoxTop <= (offsetY - chapterMenuBoxHeight) || chapterMenuBoxTop >= offsetY) chapterMenuBox.scrollTo(0, offsetY);
        },
        // 章节滚动事件
        onChapterMove(event, chapter) {
            // 根据内容移动情况来更新导航栏选中状态
            this.currentChapterId = chapter.chapterId;
            this.$emit('update:chapterId', this.currentChapterId);
            this.scrollChapterMenuBox(this.currentChapterId);
        },

        // 键盘事件监听器
        keyEventCallback(event) {
            const { keyCode } = event;
            switch (keyCode) {
            case 38:
                this.preChapterHandle();
                break;
            case 40:
                this.nextChapterHandle();
                break;
            default: break;
            }
        },
        // 获取当前章节下标和章节列表长度
        getCurrentChapterData() {
            return {
                currentChapterIndex: this.cloneChapterList.indexOf(this.currentChapter), // 当前章节下标
                len: this.cloneChapterList.length
            };
        },
        // 上一章节
        preChapterHandle() {
            const { currentChapterIndex } = this.getCurrentChapterData();
            // 第一章
            if (currentChapterIndex === 0) {
                this.message && this.message.close();
                this.message = this.$message.success('这是第一章节了哦！');
            } else {
                this.chapterChange(this.cloneChapterList[currentChapterIndex - 1].chapterId);
            }
        },
        // 下一章节
        nextChapterHandle() {
            const { currentChapterIndex, len } = this.getCurrentChapterData();
            // 最后一章
            if (len === currentChapterIndex + 1) {
                this.message && this.message.close();
                this.message = this.$message.success('这是最新的章节了哦！');
            } else {
                this.chapterChange(this.cloneChapterList[currentChapterIndex + 1].chapterId);
            }
        }
    }
};
</script>
<style lang="scss">
    @import '../../../../styles/var.scss';
    .chapter-view {
        .el-dialog {
            overflow: hidden;
            background-color: #999;
            border-radius: 0;
        }
        &-header {
            display: flex;
            padding: 0 60px 0 20px;
            height: 60px;
            align-items: center;
            background: #000;
            a {
                color: #fff;
            }
            .el-icon-arrow-left {
                color: rgba(255, 255, 255, 0.5);
            }
            &-center {
                flex: 1;
                text-align: center;
            }
            &-switch {
                margin-left: 10px;
                display: inline-block;
                &__page{
                    margin-right: 20px;
                    color:#909399;
                }
                &__desc{
                    color:#909399;
                }
            }
            .el-input__inner {
                border-radius: 16px;
                border-color: rgba(255, 255, 255, 0.1);
                background-color: rgba(255, 255, 255, 0.05);
            }
            .el-select .el-input.is-focus .el-input__inner {
                border-color: rgba(255, 255, 255, 0.15);
            }
            .el-select:hover .el-input__inner {
                border-color: rgba(255, 255, 255, 0.2);
            }
            .el-select .el-input .el-select__caret {
                color: rgba(255, 255, 255, 0.25);
            }
        }
        .el-dialog__header, .el-dialog__body{
            padding: 0;
        }
        .el-dialog__headerbtn {
            top: 20px !important;
        }
        .el-dialog__body {
            height: calc(100% - 60px);
        }
        .el-dialog__body{
            position: relative;
        }
        &-goback {
            display: flex;
            align-items: center;
            &-text {
                margin-left: 5px;
                font-size: 16px;
                color: rgba(255, 255, 255, 0.75);
            }
        }
        &-body {
            position: relative;
            width: 800px;
            height: 100%;
            padding: 5px 0 0;
            overflow: hidden;
            margin: 0 auto;
            background-color: $color-info;
            position: relative;
            img{
                width: 100%;
            }
            img[lazy=error]{
                width: 200px;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
        &-chapter-select{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-flow: row nowrap;
        }
        &-page-menu{
            position: relative;
            width: 150px;
            height: 300px;
            overflow: hidden;
            position: fixed;
            top: 60px;
            right: 15px;
            z-index: 999;
            background: $color-text-regular;
        }
        &-page-item{
            color: rgba(255, 255, 255, 0.75);
            width: auto;
            height: 30px;
            line-height: 30px;
            font-size: 14px;
            padding: 0 10px;
            cursor: pointer;
            &:hover, &.active{
                background: $color-primary-light-2;
            }
            &.unshelve {
                color: $color-danger;
            }
        }
        .chapter-page-option{
            color: $color-white;
            font-weight: bold;
            .chapter-page-current{
                color: $color-primary;
            }
        }
    }
</style>
