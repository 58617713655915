<template>
    <div class="lazy-scroll" ref="pageContainer" v-scrollbar>
        <ul class="lazy-scroll-content" ref="pageContent">
            <li class="lazy-scroll-content__chapter"
                v-for="chapter in viewChapterList"
                :key="chapter.chapterId"
                :id="'preview' + chapter.chapterId"
                :chapterSN="chapter.chapterSN"
                :orderNum="chapter.ordernum"
            >
                <div class="lazy-scroll-content__item" :chapter-index="index + 1" :chapter-pid="chapter.chapterId" v-for="(image, index) in chapter.imageAddr" :key="image">
                    <img class="lazy-scroll-content__img" :src="formatImageUrl(image)" alt="">
                </div>
            </li>
        </ul>
    </div>
</template>

<script>
import { throttle, cloneDeep } from '@/libs/utils';
const noop = () => {};

export default {
    /**
     * description:
     *      HorizontalView组件(左右翻页模式)继承了此组件，改动时请务必确认有无影响
     * params:
     *      pages {Array} 内容
     *      moveToChapterId {Number} 滚动中active的章节id
     *      prevPreloadNumber {Number} 向上滚动到一定距离后预加载章节数量，默认3
     *      nextPreloadNumber {Number} 向下滚动到一定距离后预加载章节数量，默认3
     *      offsetUp {Number} 定时懒加载向上章节数量，默认1
     *      offsetDown {Number} 定时懒加载向下章节数量，默认1
     *      throttleInterval {Number} 节流间隔 默认0ms
     *      throttleOvertime {Number} 节流超时时间 默认1000ms
     *
     * events:
     *      onMove {Function} 移动事件，带节流
     *      onClose {Function} 关闭窗口事件
     *      onMovePrev {Function} 向前事件，带节流
     *      onMoveNext {Function} 向后事件，带节流
    */
    props: {
        pages: { type: Array, default: () => [] },
        moveToChapterId: { type: Number },
        loading: { type: Boolean, default: false },
        prevPreloadNumber: { type: Number, default: 3 },
        nextPreloadNumber: { type: Number, default: 3 },
        offsetUp: { type: Number, default: 1 },
        offsetDown: { type: Number, default: 1 },
        throttleInterval: { type: Number, default: 0 },
        throttleOvertime: { type: Number, default: 1000 },
        onMove: { type: Function, default: noop },
        onClose: { type: Function, default: noop },
        onMovePrev: { type: Function, default: noop },
        onMoveNext: { type: Function, default: noop }
    },
    data() {
        return {
            isPreload: false, // 是否预加载中
            isSplicingChapter: false, // 是否拼接章节中（第一次加载或点击菜单栏时触发）
            viewChapterList: [], // 已加载章节列表
            viewChapterOffsetList: [], // 存储章节列表偏移量
            contentOffset: 0, // 内容总偏移
            currentPageIndex: 0, // 当前章节页码
            currentChapterId: null, // 当前章节
            currentPlayWidth: 0, // 当前阅览区域宽度
            scrollContainer: null, // 滚动容器
            loadDistance: 33 // 预加载距离值
        };
    },
    watch: {
        moveToChapterId: {
            immediate: true,
            handler() { // 相同章节不触发动作
                if (this.currentChapter && this.currentChapter.chapterId === this.moveToChapterId) {
                    return;
                }

                const currentChapterData = this.pages.find(item => item.chapterId === this.moveToChapterId);
                const isInViewList = this.viewChapterList.includes(currentChapterData);
                // 不在展示列表中就拼接，并且滚动到目标章节，如果在展示列表中就直接滚动到目标章节
                if (!isInViewList) {
                    this.spliceChapterList(currentChapterData).then(() => {
                        this.scrollTo(currentChapterData);
                        this.$nextTick(() => {
                            this.isSplicingChapter = false;
                        });
                    });
                    return;
                }
                this.scrollTo(currentChapterData);
            }
        }
    },
    mounted() {
        this.initData();
        this.initEvent();
    },
    beforeDestroy() {
        this.dispose();
    },
    methods: {
        // 资源释放
        dispose() {
            this.scrollContainer.removeEventListener('ps-scroll-up', this.createThrottle(this._onPrevPage));
            this.scrollContainer.removeEventListener('ps-scroll-down', this.createThrottle(this._onNextPage));
        },
        closePop() {
            this.$emit('onClose');
        },
        // 初始化事件
        initEvent() {
            this.scrollContainer.addEventListener('ps-scroll-up', this.createThrottle(this._onPrevPage));
            this.scrollContainer.addEventListener('ps-scroll-down', this.createThrottle(this._onNextPage));
        },
        // 设置初始化数据
        initData() {
            this.scrollContainer = this.$refs.pageContainer;
            this.currentPlayWidth = this.$refs.pageContent.clientWidth;
        },
        createThrottle(fn) {
            return throttle(fn, this.throttleInterval);
        },
        // 格式化章节图片地址
        formatImageUrl(url) {
            // 读取定宽800，高度自适应图片
            const _url = url ? (this.$config.cdn + url + '?imageView2/2/w/800') : '';
            return this.$utils.filterImagePath(_url);
        },
        // 拼接展示章节
        spliceChapterList(currentChapterData) {
            this.isSplicingChapter = true;
            const exists = this.pages.indexOf(currentChapterData);
            if (exists === -1) {
                return Promise.resolve(false);
            }

            const commonFunc = () => {
                return new Promise((resolve, reject) => {
                    this.computeChapterOffsetList();
                    // 获取当前章节在列表中的序号，（用于点击目录定位currentPageIndex）
                    this.currentPageIndex = this.viewChapterList.indexOf(currentChapterData);
                    resolve(true);
                });
            };

            // 找到插入位置
            let insertIndex = 0;
            if (this.viewChapterList.length > 0) {
                insertIndex = this.viewChapterList.findIndex((item) => {
                    return item.ordernum > currentChapterData.ordernum;
                });
                insertIndex = insertIndex !== -1 ? insertIndex : this.viewChapterList.length;
            }

            return this.preloadChapterImage([currentChapterData]).then((data) => {
                this.viewChapterList.splice(insertIndex, 0, currentChapterData);
                this.loadComplete(data);
            }).then(commonFunc).then(() => this.preloadList('switch'));
        },
        // 预加载章节图片
        preloadChapterImage(list = []) {
            this.$emit('update:loading', true);
            const capterImgPull = cloneDeep(list);
            let imageLoadList = [];
            const that = this;
            capterImgPull.forEach((item) => {
                item.$_totalOffset = 0;
                (function(capter) {
                    const imageList = capter.imageAddr.map(src => that.formatImageUrl(src));
                    const promiseTemp = imageList.map(url => {
                        return new Promise((resolve, reject) => {
                            let image = new Image();
                            image.onload = () => {
                                that.imgLoaded(image, capter);
                                resolve();
                                image = null;
                            };
                            image.src = url;
                        });
                    });
                    imageLoadList = imageLoadList.concat(promiseTemp);
                })(item);
            });
            return Promise.all(imageLoadList).then(res => {
                return capterImgPull;
            });
        },
        imgLoaded(image, capter) { // 根据容器宽度按比例计算图片实际偏移（高度）
            const realHeight = this.currentPlayWidth / (image.width / image.height);
            capter.$_totalOffset += realHeight + 2; // 每张图多加2像素边框高度
        },
        loadComplete(capterImgPull) { // 加载完成时，赋值每个章节容器高度
            this.$nextTick(() => {
                capterImgPull.forEach((capter) => {
                    const dom = document.getElementById('preview' + capter.chapterId);
                    if (dom) {
                        dom.style.height = capter.$_totalOffset + 'px';
                    }
                    const targetDOM = this.scrollContainer.querySelector(`#preview${capter.chapterId}`);
                    this.$emit('onRunImage', targetDOM.querySelectorAll('.lazy-scroll-content__item'));
                });
            });
        },
        // 计算章节偏移量列表
        computeChapterOffsetList() {
            const pageContents = this.$refs.pageContent.querySelectorAll('.lazy-scroll-content__chapter');
            let contentOffset = 0;
            const _viewChapterOffsetList = [];

            pageContents.forEach(item => {
                contentOffset += item.clientHeight;
                _viewChapterOffsetList.push(contentOffset);
            });
            this.viewChapterOffsetList = _viewChapterOffsetList;
            this.contentOffset = contentOffset;
        },
        /**
         * 滚动到某一章节
         * params
         * chapter {Number|Object} 章节，可以是章节id或者章节对象
        */
        scrollTo(chapter) {
            if (typeof chapter === 'object') this._scrollTo(chapter.chapterId);
            else if (typeof chapter === 'number') this._scrollTo(chapter);
            else throw TypeError('params is error， expect number or object');
        },
        // 滚动方法
        _scrollTo(id) {
            const targetDOM = this.scrollContainer.querySelector(`#preview${id}`);
            this._scrollToElement(targetDOM);
            this.$emit('onRunImage', targetDOM.querySelectorAll('.lazy-scroll-content__item'));
        },
        // 滚动到某个元素
        _scrollToElement(el, options = { behavior: 'auto', block: 'start' }) {
            el.scrollIntoView(options);
        },
        // 预加载当前话的前后连续三话
        preloadList(mode = 'switch') {
            const loadMode = ['prev', 'next', 'switch'];
            const curIndex = this.currentPageIndex; // 当前章节序号
            const currentChapter = this.viewChapterList[curIndex]; // 当前章节内容
            const originIndex = this.pages.indexOf(currentChapter); // 当前章节对应原始列表序号
            const originLength = this.pages.length - 1; // 原始列表总条数 - 1
            const isPre = mode === loadMode[0];
            const isNext = mode === loadMode[1];
            const isSwitch = mode === loadMode[2];
            let prevList = [];
            let nextList = [];
            if (isPre || isSwitch) {
                prevList = this.getPrevList(currentChapter, originIndex, isSwitch);
            }
            if (isNext || isSwitch) {
                nextList = this.getNextList(currentChapter, originIndex, originLength, isSwitch);
            }
            if (prevList.length === 0 && nextList.length === 0) {
                this.$emit('update:loading', false);
                return Promise.resolve(false);
            }

            return this.actionLoad(prevList, nextList, curIndex);
        },
        // 加载后三话
        getNextList(currentChapter, originIndex, originLength, isSwitch) {
            // 向下滚动，如果下一话不在已加载列表中，则预加载紧邻的后三话
            let curIndex = this.pages.indexOf(currentChapter);
            let tempIndex = curIndex + 1;
            tempIndex = tempIndex > originLength ? originLength : tempIndex;
            const tempCapter = this.pages[tempIndex];
            const existsIndex = this.viewChapterList.indexOf(tempCapter);
            if (existsIndex !== -1) { // 存在则不加载
                return [];
            }

            if (!isSwitch) { // 点击目录切换时，不进行判断，只用于上下手动滚动
                const curViewIndex = this.viewChapterList.indexOf(currentChapter);
                const bottomOffset = this.viewChapterOffsetList[curViewIndex];
                const curOffset = this.getContainerOffset();
                const fixedWidth = this.getFiexdScrollWrapWidth();
                if (bottomOffset - curOffset > this.loadDistance + fixedWidth) { //  如果距离底部大于指定像素，则不加载
                    return [];
                }
            }

            let start = originIndex + 1;
            start = start > originLength ? originLength : start;
            let end = originIndex + 4;
            end = end > originLength ? originLength + 1 : end;
            return this.getNewList(start, end);
        },
        // 滚动容器宽（竖屏为高，横屏为宽）
        getFiexdScrollWrapWidth() {
            return this.scrollContainer.clientHeight;
        },
        // 加载前三话
        getPrevList(currentChapter, originIndex, isSwitch) {
            // 向上滚动，如果上一话不在已加载列表中，则预加载紧邻的前三话
            let tempIndex = this.pages.indexOf(currentChapter);
            let prevIndex = tempIndex - 1;
            prevIndex = prevIndex < 0 ? 0 : prevIndex;
            const prevCapter = this.pages[prevIndex];
            const existsIndex = this.viewChapterList.indexOf(prevCapter);
            if (existsIndex !== -1) { // 存在则不加载
                return [];
            }

            if (!isSwitch) { // 点击目录切换时，不进行判断，只用于上下手动滚动
                const curViewIndex = this.viewChapterList.indexOf(currentChapter);
                const prevOffset = this.viewChapterOffsetList[curViewIndex - 1] || 0;
                const curOffset = this.getContainerOffset();
                if (curOffset - prevOffset > this.loadDistance) { //  如果距离顶部大于指定像素，则不加载
                    return [];
                }
            }

            let start = originIndex - 3;
            start = start < 0 ? 0 : start;
            let end = originIndex;
            return this.getNewList(start, end);
        },
        // 获取需要加载的章节列表
        getNewList(start, end) {
            const newList = this.pages.slice(start, end);
            const exists = this.viewChapterList.map((item) => {
                return item.chapterId;
            });
            return newList.filter((item) => {
                return exists.indexOf(item.chapterId) === -1;
            });
        },
        // 加载图片并且更新加载队列和界面
        actionLoad(prevList, nextList, curIndex) {
            const currentChapter = this.viewChapterList[curIndex];
            this.isPreload = true;
            return this.preloadChapterImage(prevList.concat(nextList)).then((data) => {
                return new Promise((resolve) => {
                    // 给500毫秒延迟，loading特效,防止在快速加载中没有反馈
                    setTimeout(() => {
                        if (prevList.length > 0) {
                            this.viewChapterList.splice(curIndex, 0, ...prevList);
                        }
                        if (nextList.length > 0) {
                            this.viewChapterList.splice(curIndex + prevList.length + 1, 0, ...nextList);
                        }
                        this.loadComplete(data);
                        resolve(data);
                    }, 500);
                });
            }).then(() => {
                this.computeChapterOffsetList();
                // 矫正currentPageIndex，用于修复加载dom时，自动滚动后产生根据定位获取index错误
                this.currentPageIndex = this.viewChapterList.indexOf(currentChapter);

                this.fixUpMove(prevList);
                this.$emit('update:loading', false);
                this.isPreload = false;
            });
        },
        // 如果有向上预加载，则需要修复滚动条至加载前的位置
        fixUpMove(prevList) {
            if (prevList.length > 0) {
                const offsetTotal = this.viewChapterOffsetList[this.currentPageIndex - 1] || 0;
                this.setContainerOffset(offsetTotal);
            }
        },
        // 获取偏移量
        getContainerOffset() {
            return this.scrollContainer.scrollTop;
        },
        // 设置偏移量
        setContainerOffset(offset) {
            this.scrollContainer.scrollTop = offset;
        },
        // 根据scrollTop来获取当前预览章节的下标
        getCurentIndexByOffset(offset) {
            return this.viewChapterOffsetList.findIndex(item => offset < item);
        },

        /** events **/
        // 滚动事件
        _onMove(event, currentChapter) {
            this.$emit('onMove', event, currentChapter);
        },
        // 向上滚动事件
        _onPrevPage(event) {
            if (this.isPreload || this.isSplicingChapter) {
                return;
            }
            const currentChapter = this.getMoveCurCapterInfo();
            this._onMove(event, currentChapter);
            this.$emit('onMovePrev', event, currentChapter);
            this.preloadList('prev');
        },
        // 向下滚动事件
        _onNextPage(event) {
            if (this.isPreload || this.isSplicingChapter) {
                return;
            }
            const currentChapter = this.getMoveCurCapterInfo();
            this._onMove(event, currentChapter);
            this.$emit('onMoveNext', event, currentChapter);
            this.preloadList('next');
        },
        // 获取实时滚动中的章节信息
        getMoveCurCapterInfo() {
            const offset = this.getContainerOffset();
            let curIndex = this.getCurentIndexByOffset(offset);
            let currentChapter = this.viewChapterList[curIndex];
            this.currentPageIndex = curIndex;
            this.currentChapter = currentChapter; // 记录章节信息，用于watch（moveToChapterId）触发时验证
            return cloneDeep(currentChapter);
        }
    }
};
</script>

<style lang="scss" scoped>
    .lazy-scroll{
        width: 100%;
        height: 100%;
        position: relative;
        overflow-y: scroll;

        .lazy-scroll-content__item{
            border-bottom: 2px solid red;
        }
        .lazy-scroll-content__img{
            display: block;
        }
    }
</style>
