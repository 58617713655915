<template>
    <div class="lazy-scroll is-horizontal" ref="pageContainer">
        <ul class="lazy-scroll-content" ref="pageContent">
           <li class="lazy-scroll-content__chapter"
                v-for="chapter in viewChapterList"
                :key="chapter.chapterId"
                :id="'preview' + chapter.chapterId"
                :chapterSN="chapter.chapterSN"
                :orderNum="chapter.ordernum"
            >
                <div class="lazy-scroll-content__item" v-for="image in chapter.imageAddr" :key="image">
                    <img class="lazy-scroll-content__img"  :src="formatImageUrl(image)" alt="">
                </div>
            </li>
        </ul>
        <div class="lazy-scroll-control">
            <el-button type="primary" size="mini" @click="closePop">关闭</el-button>
            <el-button type="primary" size="mini" @click="cliPrev">上一张</el-button>
            <el-button type="primary" size="mini" @click="cliNext">下一张</el-button>
        </div>
    </div>
</template>

<script>
import dom from '@/libs/dom';
import VerticalView from './VerticalView';

/**
  * description:
  *    继承自VerticalView
*/
export default {
    extends: VerticalView,
    data() {
        return {
            scrollElement: null, // 滚动元素
            moveWidth: 800 // 水平方式每次平移量
        };
    },
    methods: {
        // 资源释放
        dispose() {
            document.onkeyup = null;
        },
        // 初始化数据
        initData() {
            this.scrollContainer = this.$refs.pageContainer;
            this.scrollElement = this.$refs.pageContent;
        },
        // 初始化事件
        initEvent() {
            document.onkeyup = this.keyEventCallback;
        },
        // 键盘事件监听器
        keyEventCallback(event) {
            const { keyCode } = event;
            switch (keyCode) {
            case 37:
                this.cliPrev();
                break;
            case 39:
                this.cliNext();
                break;
            default: break;
            }
        },
        // 滚动方法
        _scrollTo(id) {
            const curIndex = this.viewChapterList.findIndex(chapter => chapter.chapterId === id);
            const offset = this.viewChapterOffsetList[curIndex - 1] || 0;
            this.setContainerOffset(offset);

            // 预加载上下连续章节
            this._onPrevPage();
            this._onNextPage();
        },
        // 滚动容器宽（竖屏为高，横屏为宽）
        getFiexdScrollWrapWidth() {
            return this.scrollContainer.clientWidth;
        },
        // 计算章节偏移量列表
        computeChapterOffsetList() {
            const pageContents = this.scrollElement.querySelectorAll('.lazy-scroll-content__chapter');
            let contentOffset = 0;
            const _viewChapterOffsetList = [];

            pageContents.forEach(item => {
                contentOffset += dom.children(item).length * this.moveWidth;
                _viewChapterOffsetList.push(contentOffset);
            });
            this.viewChapterOffsetList = _viewChapterOffsetList;
            this.scrollElement.style.width = contentOffset + 'px';
        },
        // 获取偏移量
        getContainerOffset() {
            return Number(this.scrollElement.style.left.replace('-', '').replace('px', ''));
        },
        // 设置偏移量
        setContainerOffset(offset) {
            this.scrollElement.style.left = '-' + offset + 'px';
        },
        loadComplete() {
            // 横屏不做处理，竖屏需要计算高度
        },
        // 向左
        cliPrev() {
            if (this.isPreload || this.isSplicingChapter) {
                return;
            }
            let offset = this.getContainerOffset();
            offset -= this.moveWidth;
            if (offset >= 0) {
                this.setContainerOffset(offset);
            }
            this._onPrevPage();
        },
        // 向右
        cliNext() {
            if (this.isPreload || this.isSplicingChapter) {
                return;
            }
            let offset = this.getContainerOffset();
            offset += this.moveWidth;
            if (offset < this.viewChapterOffsetList.slice(-1)) {
                this.setContainerOffset(offset);
            }
            this._onNextPage();
        }
    }
};
</script>

<style lang="scss" scoped>
    .lazy-scroll{
        width: 100%;
        height: 100%;
        position: relative;
        overflow-y: auto;

        &-control{
            width: 100%;
            height: 50px;
            line-height: 50px;
            background: #000;
            position: absolute;
            bottom: 0;
            text-align: center;
        }
        &-content{
            height: 100%;
        }

        &.is-horizontal{
            overflow: hidden;
            .lazy-scroll-content{
                position: relative;
                overflow: hidden;
                &__chapter{
                    height: 100%;
                    float: left;
                    overflow: hidden;
                }
                &__item{
                    width: 800px;
                    height: 100%;
                    overflow-y: auto;
                    text-align: center;
                    float: left;
                }
                &__img{
                    width: 100%;
                }
            }
        }
    }
</style>
