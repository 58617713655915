import { render, staticRenderFns } from "./VerticalView.vue?vue&type=template&id=f57d2c34&scoped=true"
import script from "./VerticalView.vue?vue&type=script&lang=js"
export * from "./VerticalView.vue?vue&type=script&lang=js"
import style0 from "./VerticalView.vue?vue&type=style&index=0&id=f57d2c34&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f57d2c34",
  null
  
)

export default component.exports