<template>
  <div @keyup.esc="close">
    <div class="image-preview__fire" @click="fire">
      <slot/>
    </div>
    <div class="pop__image-preview" v-if="visible">
      <div class="pop__main">
        <div class="pop__mask">
          <!-- <img/> 动态生成图片 -->
        </div>
        <i class="el-icon-close" @click="close"></i>
      </div>
    </div>
  </div>
</template>

<script>

export default {
    props: {
        src: String
    },
    data() {
        return {
            visible: false, // 是否显示
            mask: null
        };
    },
    methods: {
        fire() {
            this.visible = true;
            this.$nextTick(() => {
                this.mask = this.$el.querySelector('.pop__image-preview');
                const imgWrap = this.mask.querySelector('.pop__mask');
                const img = new Image();
                img.onload = () => {
                    this.imgResize(img);
                    imgWrap.appendChild(img);
                };
                img.src = this.src;
                document.body.appendChild(this.mask);
            });
        },
        imgResize(img) {
            const docRef = document.documentElement;
            const imgRatio = img.width / img.height; // 图片比例
            const baseRatios = 0.8; // 基准比例
            const docSize = { width: docRef.clientWidth, height: docRef.clientHeight }; // 可视区域尺寸
            let tempWidth = 0;
            let tempHeight = 0;

            tempWidth = docSize.width * baseRatios;
            tempHeight = tempWidth / imgRatio;
            if (tempHeight > docRef.clientHeight * baseRatios) {
                tempHeight = docSize.height * baseRatios;
                tempWidth = tempHeight * imgRatio;
            }
            img.width = tempWidth;
            img.height = tempHeight;
        },
        close() {
            this.visible = false;
            this.$el.appendChild(this.mask);
        }
    }
};
</script>

<style lang='scss' scoped>
.image-preview__fire {
  cursor: pointer;
}
.pop__image-preview {
  .pop__main {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 999;
    .el-icon-close {
      position: absolute;
      right: 5px;
      top: 60px;
      color: #fff;
      font-size: 34px;
      cursor: pointer;
    }
    .pop__mask {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background: rgba(0, 0, 0, 0.8);
    }
  }
}
</style>
